import { createReducer } from '@reduxjs/toolkit';

import { mapRequestModelByRMTypeId } from './requestModelList';
import ActionType, { ActionTypeById } from '../enums/actionType';
import OperatorType from '../enums/operatorType';
import ParameterType from '../enums/parameterType';
import { getSmParams } from './parameters';

import { enqueueSnackbar } from './notify';

import utils from '../utils/utils';
import apiClient from '../utils/apiClient';
import templateApi from '../utils/api/customTemplateApi';
import getApiCaller from '../utils/apiClientCaller';

import smsUtils from '../components/common/contentEditor/utils/smsUtils';
import contentEditorUtils from '../components/common/contentEditor/utils/contentEditorUtils';
import { loaderActions } from './loader';

const initialState = {
  editedCampaign: null, // campaign visible in the editor
  selectedType: null, // selected requestmodel (used at selection, before campaign is saved)
  recipeFieldsConfig: {
    // will contain all data for the recipe of the edited campaign (step 2 of campaign editor)
    setupId: null, // setup id for the current edited recipe
    activeFields: [], // all fields that has a corresponding value in the setup value or is mandatory in the requestModelParams. each entry will have at least a field descriptor(object: model), and may have a value(object : value)
    inactiveFields: [], // opposite of activeFields.most of the time only a descriptor model will be stored.
    requestModelParams: [], // a list of all the avalaible field descriptors for the edited campaign requestmodel
    loaded: false, // to know if the recipe config has been loaded and processed
  },
  actions: {
    // will contain all actions and avalaible actiontypes for the edited campaign (campaign editor step 3)
    availableActionTypes: [],
    campaignActions: [],
    currentAction: null, // currently edited action
    modifiedCurrentAction: null, // stores all the modifications made to the content of an action -> every change in a message editor will be stored here and this is the object that will be used to save data
  },
  schedule: {
    // campaign time configuration
    editedCmpPeriods: [],
  },
  currentStep: 'infos', // to manage campaign editor active tab
};

// ------------------------------------------------------------------
/* #region SELECTORS */
// ------------------------------------------------------------------
const getEditedCampaign = state => state.campaignEditor.editedCampaign;
const getEditedCampaignFieldsConfig = state => state.campaignEditor.recipeFieldsConfig;
const getPeriods = state => state.campaignEditor.schedule.editedCmpPeriods;
const getEditedCampaignRequestModelParams = state =>
  state.campaignEditor.recipeFieldsConfig.requestModelParams;
const getCampaignEditorData = state => state.campaignEditor;
export const getCurrentActionsData = state => state.campaignEditor.actions;
export const getUnremovedPeriods = state =>
  state.campaignEditor.schedule.editedCmpPeriods.reduce((acc, period) => {
    if (!period.removed) return [...acc].concat(period);
    return acc;
  }, []);
const getModifiedContent = state => state.campaignEditor.actions.modifiedCurrentAction;
const getCurrentActionType = state => state.campaignEditor.actions.currentAction.type;

export const getCurrentRequestModelId = state => {
  // will return requestmodelid for the current editedcampaign using requestModelTypeId from editedCampaign.requestModelTypeId or selectedType.id
  const campaignEditorData = state.campaignEditor;
  const requestModelByRequestModelTypeIdMap = mapRequestModelByRMTypeId(state);
  const requestModelId =
    (campaignEditorData.editedCampaign.requestModelTypeId &&
      requestModelByRequestModelTypeIdMap[campaignEditorData.editedCampaign.requestModelTypeId] &&
      requestModelByRequestModelTypeIdMap[campaignEditorData.editedCampaign.requestModelTypeId]
        .id) ||
    (campaignEditorData.selectedType && campaignEditorData.selectedType.id);
  return requestModelId;
};

// ------------------------------------------------------------------
/* #endregion SELECTORS */
// ------------------------------------------------------------------

// ------------------------------------------------------------------
/* #region RECIPECONFIG(STEP2) */
// ------------------------------------------------------------------
/**
 * add  indexInList property to each object of a list of objects
 * used for recipeconfig activefield list, as a unique key and make it easy to retrieve/bind the corresponding field despite of recipe config changes
 */
const fetchArrayIndexes = array =>
  array.reduce((acc, item) => [...acc.slice(), { ...item, indexInList: array.indexOf(item) }], []);

/*
Les champs existant dans {config} seront répercutés dans [activeFields]
Les champs mandatory existant dans {requestModelParameters} répercutés dans [activeFields] s'ils n'existent pas dans dans {config}
Les champs existant dans {requestModelParameters} et n'existant pas dans {config} seront répercutés dans [inactiveFields]
si forceField === true, tous les champs de {requestModelParameters} seront répercutés dans [activeFields] même s'ils n'existent pas dans {config}
Ne pourra pas traiter qu'un seul niveau de champs groupés (pas de groupes imbriqués)
*/
const processRecipeConfig = (config, requestModelParameters, forceField) => {
  const processedData = {
    activeFields: [],
    inactiveFields: [],
  };

  const keysOrderInSetup = config.value.map(setupSingleVal => setupSingleVal.key);
  const sortedRmParams = [...requestModelParameters].sort((rmParam1, rmParam2) => {
    const rmParam1SetupPosition = keysOrderInSetup.indexOf(rmParam1.key);
    const rmParam2SetupPosition = keysOrderInSetup.indexOf(rmParam2.key);
    if (rmParam1SetupPosition === -1 && rmParam2SetupPosition === -1) {
      return 0;
    }
    if (rmParam1SetupPosition === -1) {
      return 1;
    }
    if (rmParam2SetupPosition === -1) {
      return -1;
    }
    return rmParam1SetupPosition - rmParam2SetupPosition;
  });

  sortedRmParams.forEach(fieldParam => {
    const valuesForKey = config.value.filter(singleValue => singleValue.key === fieldParam.key);

    // si le champ est obligatoire, posséde déjà une ou plusieurs valeurs, ou si on doit forcer le champ-->ils iront tous en activefields
    if (fieldParam.mandatory || valuesForKey.length || forceField) {
      // si le champ possede une ou des valeurs
      if (valuesForKey.length) {
        // si c'est un champ groupé
        if (fieldParam.type === 'GROUP') {
          const keyList = [];
          const activeFieldsByKey = {};
          let activeFieldList = fetchArrayIndexes(
            processRecipeConfig({ value: valuesForKey[0].values }, fieldParam.descriptors, true)
              .activeFields
          );

          activeFieldList = activeFieldList.map(activeField => ({
            ...activeField,
            parentKey: fieldParam.key,
            // parentIndex: processedData.activeFields.length,
          }));

          activeFieldList.forEach(activeField => {
            if (!activeFieldsByKey[activeField.model.key]) {
              activeFieldsByKey[activeField.model.key] = [];
            }
            activeFieldsByKey[activeField.model.key].push({ ...activeField });
          });

          fieldParam.descriptors.forEach(groupFieldconfig => {
            keyList.push(groupFieldconfig.key);
          });

          processedData.activeFields.push({
            indexInList: processedData.activeFields.length,
            model: {
              ...fieldParam,
              groupConfig: {
                keyList,
                activeFieldList,
                activeFieldsByKey,
              },
            },
          });
        } else {
          // si ce n'est pas un champ groupé
          valuesForKey.forEach(singleValue => {
            processedData.activeFields.push({
              model: { ...fieldParam },
              indexInList: processedData.activeFields.length,
              value: { ...singleValue },
            });
          });
        }
      } else {
        // ou si le champ est obligatoire ou forcé
        const indexInList = processedData.activeFields.length;
        switch (fieldParam.type) {
          case 'GROUP': {
            const keyList = [];
            const activeFieldsByKey = {}; // -->defaultFields?
            let activeFieldList = fetchArrayIndexes(
              processRecipeConfig({ value: [] }, fieldParam.descriptors, true).activeFields
            );

            activeFieldList = activeFieldList.map(activeField => ({
              ...activeField,
              parentKey: fieldParam.key,
            }));

            activeFieldList.forEach(activeField => {
              if (!activeFieldsByKey[activeField.model.key]) {
                activeFieldsByKey[activeField.model.key] = [];
              }
              activeFieldsByKey[activeField.model.key].push({ ...activeField });
            });

            fieldParam.descriptors.forEach(groupFieldconfig => {
              keyList.push(groupFieldconfig.key);
            });

            processedData.activeFields.push({
              indexInList,
              model: {
                ...fieldParam,
                groupConfig: {
                  keyList,
                  activeFieldList,
                  activeFieldsByKey,
                },
              },
            });
            break;
          }
          default: {
            if (fieldParam.allowedOperators.length === 1) {
              processedData.activeFields.push({
                model: { ...fieldParam },
                indexInList,
                value: {
                  key: fieldParam.key,
                  values: [],
                  java: fieldParam.javaParameter,
                  typeId: ParameterType[fieldParam.type],
                  operatorId: OperatorType[fieldParam.allowedOperators[0]],
                },
              });
            } else {
              processedData.activeFields.push({
                model: { ...fieldParam },
                indexInList,
                value: {
                  key: fieldParam.key,
                  values: [],
                  java: fieldParam.javaParameter,
                  typeId: ParameterType[fieldParam.type],
                },
              });
            }
          }
        }
      }
    } else {
      switch (fieldParam.type) {
        case 'GROUP': {
          const keyList = [];
          const activeFieldsByKey = {}; // -->defaultFields?
          let activeFieldList = fetchArrayIndexes(
            processRecipeConfig({ value: [] }, fieldParam.descriptors, true).activeFields
          );

          activeFieldList = activeFieldList.map(activeField => ({
            ...activeField,
            parentKey: fieldParam.key,
          }));

          activeFieldList.forEach(activeField => {
            if (!activeFieldsByKey[activeField.model.key]) {
              activeFieldsByKey[activeField.model.key] = [];
            }
            activeFieldsByKey[activeField.model.key].push({ ...activeField });
          });

          fieldParam.descriptors.forEach(groupFieldconfig => {
            keyList.push(groupFieldconfig.key);
          });

          processedData.inactiveFields.push({
            ...fieldParam,
            groupConfig: {
              keyList,
              activeFieldList,
              activeFieldsByKey,
            },
          });
          break;
        }
        default:
          processedData.inactiveFields.push({ ...fieldParam });
      }
    }
  });
  return processedData;
};

const saveEditedRecipe = async (campaignEditorData, callApi /* clubId */) => {
  // si on sauvegarde sans avoir édité la recette, le campaignEditorData.recipeFieldsConfig.setupId sera null, et effacé en base...meme si la recette restera la même
  // donc extraire le setupid de la recette
  const recipeToSave = await callApi(apiClient.insertOrUpdateRecipe, [
    {
      id: campaignEditorData.editedCampaign.recipeId,
      setupId: campaignEditorData.recipeFieldsConfig.setupId,
      name: campaignEditorData.editedCampaign.name,
      requestModelTypeId:
        campaignEditorData.editedCampaign.requestModelTypeId ||
        campaignEditorData.selectedType.requestModelTypeId,
    },
  ]);
  return recipeToSave;
};
/*
construit un objet à partir de recipeFieldsConfig.activeFields
qui sera enregistré en base sous forme json
setup updaté en base si setupId, sinon setup créé en base
retourne l'id du setup créé ou modifié
!!!saveEdited, mais n'importe quel recipeFieldsConfig peut être passé. ne prend pas en compté l'état de l'app
*/
const saveEditedRecipeSetup = async (recipeFieldsConfig, callApi /* clubId */) => {
  const setup = {
    id: recipeFieldsConfig.setupId,
    value: [],
  };

  recipeFieldsConfig.activeFields.forEach(field => {
    if (field.model.type === 'GROUP' && field.model.groupConfig.activeFieldList) {
      const groupValue = {
        key: field.model.key,
        typeId: ParameterType[field.model.type],
        values: [],
      };
      if (field.model.type === 'LINKED_GROUP') {
        groupValue.operatorId = field.value.operatorId;
      }
      field.model.groupConfig.activeFieldList.forEach(groupSubField => {
        if (groupSubField.value && groupSubField.value.values && groupSubField.value.values.length)
          groupValue.values.push({
            ...groupSubField.value,
          });
        else if (
          [OperatorType.NULL, OperatorType.NOT_NULL, OperatorType.TRUE, OperatorType.FALSE].indexOf(
            groupSubField.value.operatorId
          ) !== -1 ||
          groupSubField.model.key === 'BOOLEAN'
        )
          groupValue.values.push({
            ...groupSubField.value,
          });
      });
      if (groupValue.values.length) setup.value.push(groupValue);
    } else if (field.value && field.value.values && field.value.values.length) {
      setup.value.push(field.value);
    } else if (
      [OperatorType.NULL, OperatorType.NOT_NULL, OperatorType.TRUE, OperatorType.FALSE].indexOf(
        field.value.operatorId
      ) !== -1 ||
      field.model.key === 'BOOLEAN'
    ) {
      setup.value.push(field.value);
    }
  });
  setup.value = JSON.stringify(setup.value);
  const updatedSetup = await callApi(apiClient.insertOrUpdateSetup, [setup]);
  return updatedSetup;
};

const mapActiveFieldsByKeyReducer = (map = {}, fieldConfig) => {
  const copy = { ...map };

  if (!map[fieldConfig.model.key]) {
    copy[fieldConfig.model.key] = [];
  }

  copy[fieldConfig.model.key].push({ ...fieldConfig });

  return copy;
};
const processValueForField = (fieldConfig, value) => {
  const result = { ...fieldConfig };
  switch (fieldConfig.model.type) {
    case 'INTEGER': {
      return {
        ...result,
        value: {
          ...result.value,
          key: result.model.key,
          values: [].concat(value),
          java: result.model.javaParameter,
          typeId: ParameterType[result.model.type],
        },
      };
    }
    case 'ENUM':
    case 'ENUM_TEXT':
    case 'LINKED':
    case 'FOREIGN_KEY':
    case 'N_FOREIGN_KEY':
    case 'IFOREIGN_KEY':
    case 'TFOREIGN_KEY': {
      return {
        ...result,
        value: {
          ...result.value,
          key: result.model.key,
          values: value,
          java: result.model.javaParameter,
          typeId: ParameterType[result.model.type],
        },
      };
    }
    default:
      return { ...result };
  }
};

const onRecipeFieldChange = (state, { value }) => {
  const activeFieldsCopy = state.recipeFieldsConfig.activeFields.slice();
  // si c'est un groupe,
  if (value.fieldConfig.parentKey) {
    let { parentIndex } = value.fieldConfig;
    // WARNING this allows only one lvl group deepness :
    // a group field inside a group cannot be handled this way
    // because of parentKey that is a key contained in state.recipeFieldsConfig.activeFields
    // could be resolved with recursivity and check state.recipeFieldsConfig only after
    // the top parent has been 'iterated//processed//checked' whose parent does NOT have a parent key

    //------
    // pour les linked : mettre dans les champs l'index du champ parent?-> il peut y en avoir plusieurs dans activeFieldList, on doit savoir sur lequel il y eu modif
    // --> toujours mettre l'index parent (c meme mieux que parentKey, pour le cas de linked field avec plusieurs lignes)
    if (!parentIndex)
      // seuls les linked_group ont le parent index.
      // Les groupes normaux ne doivent avoir qu'une entrée, contrairement aux autres qui peuvent en avoir plusieurs
      // Les multiples valeurs pour un champ group normal sont représentées par des entrées multiples dans la liste de champs du groupe :
      //    les sous cles du groupe peuvent exuister plusieurs fois dans la liste des champs du groupe
      parentIndex = activeFieldsCopy.filter(
        field => field.model.key === value.fieldConfig.parentKey
      )[0].indexInList;
    activeFieldsCopy[parentIndex] = {
      ...activeFieldsCopy[parentIndex],
      model: {
        ...activeFieldsCopy[parentIndex].model,
        groupConfig: {
          ...activeFieldsCopy[parentIndex].model.groupConfig,
          activeFieldList: activeFieldsCopy[parentIndex].model.groupConfig.activeFieldList.map(
            field => {
              if (field.indexInList === value.fieldConfig.indexInList) {
                return processValueForField(field, value.value);
              }
              return {
                ...field,
              };
            }
          ),
        },
      },
    };

    activeFieldsCopy[parentIndex].model.groupConfig.activeFieldsByKey = activeFieldsCopy[
      parentIndex
    ].model.groupConfig.activeFieldList.reduce(mapActiveFieldsByKeyReducer, {});

    return {
      ...state,
      recipeFieldsConfig: { ...state.recipeFieldsConfig, activeFields: activeFieldsCopy },
    };
  }
  // si ce n'est pas un groupe
  switch (value.fieldConfig.model.type) {
    // TODO could use processValueForField function
    case 'INTEGER': {
      activeFieldsCopy[value.fieldConfig.indexInList] = {
        ...activeFieldsCopy[value.fieldConfig.indexInList],
        value: {
          ...activeFieldsCopy[value.fieldConfig.indexInList].value,
          key: activeFieldsCopy[value.fieldConfig.indexInList].model.key,
          values: [].concat(value.value),
          java: activeFieldsCopy[value.fieldConfig.indexInList].model.javaParameter,
          typeId: ParameterType[activeFieldsCopy[value.fieldConfig.indexInList].model.type],
        },
      };
      return {
        ...state,
        recipeFieldsConfig: { ...state.recipeFieldsConfig, activeFields: activeFieldsCopy },
      };
    }
    case 'LINKED_GROUP': {
      activeFieldsCopy[value.fieldConfig.indexInList] = {
        ...activeFieldsCopy[value.fieldConfig.indexInList],
        value: {
          ...activeFieldsCopy[value.fieldConfig.indexInList].value,
          key: activeFieldsCopy[value.fieldConfig.indexInList].model.key,
          values: value.value,
          java: activeFieldsCopy[value.fieldConfig.indexInList].model.javaParameter,
          typeId: ParameterType[activeFieldsCopy[value.fieldConfig.indexInList].model.type],
        },
      };
      return {
        ...state,
        recipeFieldsConfig: { ...state.recipeFieldsConfig, activeFields: activeFieldsCopy },
      };
    }
    case 'ENUM':
    case 'ENUM_TEXT':
    case 'FOREIGN_KEY':
    case 'N_FOREIGN_KEY':
    case 'IFOREIGN_KEY':
    case 'TFOREIGN_KEY': {
      activeFieldsCopy[value.fieldConfig.indexInList] = {
        ...activeFieldsCopy[value.fieldConfig.indexInList],
        value: {
          ...activeFieldsCopy[value.fieldConfig.indexInList].value,
          key: activeFieldsCopy[value.fieldConfig.indexInList].model.key,
          values: value.value,
          java: activeFieldsCopy[value.fieldConfig.indexInList].model.javaParameter,
          typeId: ParameterType[activeFieldsCopy[value.fieldConfig.indexInList].model.type],
        },
      };
      return {
        ...state,
        recipeFieldsConfig: { ...state.recipeFieldsConfig, activeFields: activeFieldsCopy },
      };
    }
    case 'CLUBS': {
      const valsByKey = value.value.reduce(
        (m, val) => ({
          ...m,
          [val.key]: m[val.key]
            ? [...m[val.key], JSON.parse(val.value).id]
            : [JSON.parse(val.value).id],
        }),
        {}
      );
      const newValueFieldKeys = Object.keys(valsByKey);

      const fieldKeys = value.fieldConfig.model.descriptors.map(d => d.key);
      const clearedKeys = fieldKeys.filter(fk => newValueFieldKeys.indexOf(fk) === -1);

      clearedKeys.forEach(fKey => {
        activeFieldsCopy[value.fieldConfig.indexInList] = {
          ...activeFieldsCopy[value.fieldConfig.indexInList],
          value: {
            ...activeFieldsCopy[value.fieldConfig.indexInList].value,
            values: activeFieldsCopy[value.fieldConfig.indexInList].value.values.filter(
              fkVal => fkVal.key !== fKey
            ),
          },
        };
      });
      newValueFieldKeys.forEach(k => {
        const isNewKey = !activeFieldsCopy[value.fieldConfig.indexInList].value.values.find(
          v => v.key === k
        );
        if (isNewKey) {
          const descriptor = value.fieldConfig.model.descriptors.find(d => d.key === k);
          const valueToPush = {
            key: descriptor.key,
            values: valsByKey[k],
            java: descriptor.javaParameter,
            typeId: ParameterType[descriptor.type],
            operatorId: value.fieldConfig.value.operatorId,
          };

          activeFieldsCopy[value.fieldConfig.indexInList] = {
            ...activeFieldsCopy[value.fieldConfig.indexInList],
            value: {
              ...activeFieldsCopy[value.fieldConfig.indexInList].value,
              values: activeFieldsCopy[value.fieldConfig.indexInList].value.values.concat(
                valueToPush
              ),
            },
          };
        } else {
          activeFieldsCopy[value.fieldConfig.indexInList] = {
            ...activeFieldsCopy[value.fieldConfig.indexInList],
            value: {
              ...activeFieldsCopy[value.fieldConfig.indexInList].value,
              values: activeFieldsCopy[value.fieldConfig.indexInList].value.values.map(fkVal =>
                fkVal.key === k
                  ? {
                      ...fkVal,
                      values: valsByKey[k],
                    }
                  : fkVal
              ),
            },
          };
        }
      });
      return {
        ...state,
        recipeFieldsConfig: { ...state.recipeFieldsConfig, activeFields: activeFieldsCopy },
      };
    }
    default:
      return { ...state };
  }
};

const onRecipeFieldOpchange = (state, { value }) => ({
  ...state,
  recipeFieldsConfig: {
    ...state.recipeFieldsConfig,
    activeFields: state.recipeFieldsConfig.activeFields.reduce((acc, field) => {
      if (value.fieldConfig.parentKey && field.model.key === value.fieldConfig.parentKey) {
        const newGroupConfig = {
          ...field.model.groupConfig,
          activeFieldList: field.model.groupConfig.activeFieldList.reduce(
            (groupFieldListAcc = [], subGroupField) => {
              if (subGroupField.indexInList === value.fieldConfig.indexInList) {
                return groupFieldListAcc.slice().concat({
                  ...subGroupField,
                  value:
                    [
                      OperatorType.NOT_NULL,
                      OperatorType.NULL,
                      OperatorType.FALSE,
                      OperatorType.TRUE,
                    ].indexOf(value.value) !== -1
                      ? {
                          ...subGroupField.value,
                          key: subGroupField.model.key,
                          operatorId: value.value,
                          values:
                            [OperatorType.NOT_NULL, OperatorType.NULL].indexOf(value.value) !== -1
                              ? []
                              : (value.value === OperatorType.TRUE && ['true']) || ['false'],
                        }
                      : {
                          ...subGroupField.value,
                          key: subGroupField.model.key,
                          operatorId: value.value,
                        },
                });
              }
              return [...groupFieldListAcc].concat({ ...subGroupField });
            },
            []
          ),
        };
        newGroupConfig.activeFieldsByKey = newGroupConfig.activeFieldList.reduce(
          mapActiveFieldsByKeyReducer,
          {}
        );
        const res = acc.slice().concat({
          ...field,
          model: {
            ...field.model,
            groupConfig: newGroupConfig,
          },
        });
        return res;
      } else if (
        field.indexInList === value.fieldConfig.indexInList &&
        value.fieldConfig.model.type === 'CLUBS'
      ) {
        return acc.slice().concat({
          ...field,
          value: {
            ...field.value,
            operatorId: value.value,
            values: field.value.values.map(v => ({ ...v, operatorId: value.value })),
          },
        });
      } else if (
        !value.fieldConfig.parentKey &&
        field.indexInList === value.fieldConfig.indexInList
      ) {
        return acc.slice().concat({
          ...field,
          value:
            [
              OperatorType.NOT_NULL,
              OperatorType.NULL,
              OperatorType.FALSE,
              OperatorType.TRUE,
            ].indexOf(value.value) !== -1
              ? {
                  ...field.value,
                  key: value.fieldConfig.model.key,
                  operatorId: value.value,
                  values:
                    [OperatorType.NOT_NULL, OperatorType.NULL].indexOf(value.value) !== -1
                      ? []
                      : (value.value === OperatorType.TRUE && ['true']) || ['false'],
                }
              : { ...field.value, key: value.fieldConfig.model.key, operatorId: value.value },
        });
      }
      return acc.slice().concat({ ...field });
    }, []),
  },
});

const receiveRecipeConfig = (state, action) => ({
  ...state,
  recipeFieldsConfig: {
    ...state.recipeFieldsConfig,
    ...action.value,
    loaded: true,
  },
});

const receiveRequestModelParams = (state, { value }) => ({
  ...state,
  recipeFieldsConfig: {
    ...state.recipeFieldsConfig,
    requestModelParams: value,
  },
});

export const addActiveField = (state, { value }) => ({
  ...state,
  recipeFieldsConfig: {
    ...state.recipeFieldsConfig,
    inactiveFields: [
      ...state.recipeFieldsConfig.inactiveFields.filter(field => field.key !== value.key),
    ],
    activeFields: [...state.recipeFieldsConfig.activeFields].concat(
      [
        { model: value, indexInList: state.recipeFieldsConfig.activeFields.length }, // this is the length before an item is added to the list
      ].map(field => {
        if (value.allowedOperators.length > 1) return field;
        return {
          ...field,
          value: {
            key: value.key,
            values: [],
            java: value.javaParameter,
            typeId: ParameterType[value.type],
            operatorId: OperatorType[value.allowedOperators[0]],
          },
        };
      })
    ),
  },
});

const addGroupSubFieldLine = (state, { value }) => {
  const { model, parentKey } = value;

  const activeFields = [...state.recipeFieldsConfig.activeFields];

  const parentGroupRecipeFieldIndex = activeFields.filter(
    topField => topField.model.key === parentKey
  )[0].indexInList;

  activeFields[parentGroupRecipeFieldIndex] = {
    ...activeFields[parentGroupRecipeFieldIndex],
    model: {
      ...activeFields[parentGroupRecipeFieldIndex].model,
      groupConfig: {
        ...activeFields[parentGroupRecipeFieldIndex].model.groupConfig,
        activeFieldList: activeFields[parentGroupRecipeFieldIndex].model.groupConfig.activeFieldList
          .map(field => ({ ...field }))
          .concat({
            model,
            parentKey,
            indexInList:
              activeFields[parentGroupRecipeFieldIndex].model.groupConfig.activeFieldList.length,
          }),
      },
    },
  };

  activeFields[parentGroupRecipeFieldIndex].model.groupConfig.activeFieldsByKey = activeFields[
    parentGroupRecipeFieldIndex
  ].model.groupConfig.activeFieldList.reduce(mapActiveFieldsByKeyReducer, {});

  return {
    ...state,
    recipeFieldsConfig: {
      ...state.recipeFieldsConfig,
      activeFields,
    },
  };
};

const removeActiveField = (state, { value }) => ({
  ...state,
  recipeFieldsConfig: {
    ...state.recipeFieldsConfig,
    inactiveFields: state.recipeFieldsConfig.inactiveFields.slice().concat(
      state.recipeFieldsConfig.activeFields
        .slice()
        .filter(activeField => activeField.model.key === value)
        .reduce((acc, removedField) => acc.slice().concat(removedField.model), [])[0]
    ),
    activeFields: fetchArrayIndexes(
      state.recipeFieldsConfig.activeFields
        .slice()
        .filter(activeField => activeField.model.key !== value)
    ).map(activeField => {
      if (activeField.model.groupConfig) {
        const eActiveField = {
          ...activeField,
          model: {
            ...activeField.model,
            groupConfig: {
              ...activeField.model.groupConfig,
              activeFieldList: activeField.model.groupConfig.activeFieldList.map(groupSubField => ({
                ...groupSubField,
                parentIndex: activeField.indexInList,
              })),
            },
          },
        };
        return eActiveField;
      }
      return activeField;
    }),
  },
});

const removeRecipeFieldLine = (state, { value }) => ({
  ...state,
  recipeFieldsConfig: {
    ...state.recipeFieldsConfig,
    activeFields: fetchArrayIndexes(
      state.recipeFieldsConfig.activeFields
        .slice()
        .filter(activeField => activeField.indexInList !== value.indexInList)
    ),
  },
});

const removeRecipeGroupFieldLine = (state, { value }) => ({
  ...state,
  recipeFieldsConfig: {
    ...state.recipeFieldsConfig,
    activeFields: state.recipeFieldsConfig.activeFields
      .slice()
      .reduce((reducedList = [], field) => {
        if (value.parentKey === field.model.key) {
          const newGroupConfig = {
            ...field.model.groupConfig,
            activeFieldList: fetchArrayIndexes(
              [...field.model.groupConfig.activeFieldList].filter(
                groupSubField => groupSubField.indexInList !== value.indexInList
              )
            ),
          };

          newGroupConfig.activeFieldsByKey = newGroupConfig.activeFieldList.reduce(
            mapActiveFieldsByKeyReducer,
            {}
          );

          return [...reducedList].concat({
            ...field,
            model: {
              ...field.model,
              groupConfig: newGroupConfig,
            },
          });
        }
        return reducedList.concat({
          ...field,
        });
      }, []),
  },
});

export const loadEmptyRecipeConfig = (requestModelTypeId, showAlert) => async (
  dispatch,
  getState
) => {
  const requestModelByRequestModelTypeIdMap = mapRequestModelByRMTypeId(getState());

  const requestModelParams = await getApiCaller(showAlert)(
    dispatch,
    getState
  )(apiClient.loadRecipeParameters, [requestModelByRequestModelTypeIdMap[requestModelTypeId].id]);

  dispatch({ type: 'RECEIVE_RM_PARAMS_MODEL', value: requestModelParams });
  const processedSetup = {
    setupId: null,
    ...processRecipeConfig({ id: null, value: [] }, requestModelParams),
  };
  dispatch({
    type: 'RECEIVE_RECIPE_CONFIG',
    value: processedSetup,
  });
};

export const checkRecipeFieldValidity = (recipeField, groupFieldConfig, selectedFields) => {
  let isValid = true;
  const messages = [];
  const testEmptyValidity = groupFieldConfig ? !!recipeField.model.mandatory : true;

  const isOperatorOnlyField =
    recipeField.model.type === 'BOOLEAN' ||
    (recipeField.value &&
      recipeField.value.operatorId &&
      [OperatorType.NULL, OperatorType.NOT_NULL, OperatorType.TRUE, OperatorType.FALSE].includes(
        recipeField.value.operatorId
      ));

  if (testEmptyValidity) {
    if (!isOperatorOnlyField)
      if (!recipeField.value || !recipeField.value.values || !recipeField.value.values.length) {
        isValid = false;
        messages.push(utils.getLang('smartmessaging.fieldValidity.mandatory'));
      }
    if (isValid && !recipeField.value.operatorId) {
      isValid = false;
      messages.push(utils.getLang('smartmessaging.fieldValidity.requiredOperator'));
    }
  }

  if (
    isValid &&
    ((recipeField.value && recipeField.value.values && recipeField.value.values.length) ||
      !!(recipeField.value && recipeField.value.operatorId))
  ) {
    if (!recipeField.value.operatorId) {
      isValid = false;
      messages.push(utils.getLang('smartmessaging.fieldValidity.requiredOperator'));
    } else if (recipeField.value.values && recipeField.value.values.length) {
      const twinCriterias = selectedFields.filter(
        pField => pField.model.key === recipeField.model.key
      );
      const usedOperators = twinCriterias
        .map(rCrit => rCrit.value.operatorId)
        .filter(opId => opId === 0 || !!opId);
      const opCount = usedOperators.reduce(
        (count, op) => (op !== recipeField.value.operatorId ? count : count + 1),
        0
      );
      if (opCount > 1) {
        isValid = false;
        messages.push(utils.getLang('smartmessaging.massAction.tooltip.duplicateOperator'));
      }
      switch (recipeField.value.operatorId) {
        case OperatorType.BETWEEN:
        case OperatorType.NOT_BETWEEN: {
          if (recipeField.value.values.length === 1) {
            messages.push(utils.getLang('smartmessaging.fieldValidity.mandatory'));
            isValid = false;
          }
          if (recipeField.value.values.length > 1) {
            recipeField.value.values.forEach(value => {
              if (!value) {
                if (isValid) {
                  messages.push(utils.getLang('smartmessaging.fieldValidity.mandatory'));
                } else {
                  messages.push(utils.getLang('smartmessaging.fieldValidity.mandatory'));
                }
                isValid = false;
              }
            });
          }
          break;
        }
        default:
          isValid = isValid && !!recipeField.value.values[0];
          if (!isValid) messages.push(utils.getLang('smartmessaging.fieldValidity.mandatory'));
      }
    } else if (!isOperatorOnlyField) {
      isValid = false;
      messages.push(utils.getLang('smartmessaging.fieldValidity.mandatory'));
    }
  }
  return {
    key: recipeField.model.key,
    isValid,
    messages,
    index: recipeField.indexInList,
    parentIndex: groupFieldConfig && groupFieldConfig.indexInList,
  };
};

export const checkRecipeCfgValidity = state => {
  const recipeCfg = getEditedCampaignFieldsConfig(state);

  const recipeCfgValidity = { isValid: true, invalidFields: [] };

  recipeCfg.activeFields.forEach(field => {
    switch (field.model.type) {
      case 'GROUP': {
        field.model.groupConfig.activeFieldList.forEach(groupSubField => {
          const fieldValidity = checkRecipeFieldValidity(
            groupSubField,
            field,
            recipeCfg.activeFields
          );
          if (recipeCfgValidity.isValid) {
            recipeCfgValidity.isValid = fieldValidity.isValid;
          } else {
            recipeCfgValidity.invalidFields.push(fieldValidity);
          }
        });
        break;
      }
      default: {
        const fieldValidity = checkRecipeFieldValidity(field, null, recipeCfg.activeFields);
        if (recipeCfgValidity.isValid) {
          recipeCfgValidity.isValid = fieldValidity.isValid;
        }
        if (!fieldValidity.isValid) {
          recipeCfgValidity.invalidFields.push(fieldValidity);
        }
        break;
      }
    }
  });
  return recipeCfgValidity;
};

export const mapActiveFieldsByKey = state =>
  state.campaignEditor.recipeFieldsConfig.activeFields.reduce(mapActiveFieldsByKeyReducer, {});
// ------------------------------------------------------------------
/* #endregion RECIPECONFIG(STEP2) */
// ------------------------------------------------------------------

/* #region CAMPAIGNACTIONS (STEP3) */
const clearCurrentAction = state => ({
  ...state,
  actions: { ...state.actions, currentAction: null },
});
const receiveMailContent = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    currentAction: {
      ...state.actions.currentAction,
      id: (!!value.id && value.id) || null,
      type: value.type || ActionType.EMAIL.id,
      messageModel: value.messageModel,
      customTemplate: value.customTemplate,
      messageId: (!!value.messageId && value.messageId) || null,
      content: {
        object: value.object || '',
        content: value.content || '',
      },
      language: value.language || null,
    },
    modifiedCurrentAction: {
      content: {
        object: value.object || '',
        content: value.content || '',
      },
      language: value.language || null,
    },
  },
});

const receiveSmsContent = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    currentAction: {
      ...state.actions.currentAction,
      id: (!!value.id && value.id) || null,
      messageModel: value.messageModel,
      customTemplate: value.customTemplate,
      type: ActionType.SMS.id,
      messageId: (!!value.messageId && value.messageId) || null,
      content: (!!value.content && value.content) || '',
      language: value.language || null,
    },
    modifiedCurrentAction: {
      content: (!!value.content && value.content) || '',
      language: value.language || null,
    },
  },
});

const receiveNotificationContent = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    currentAction: {
      ...state.actions.currentAction,
      id: (!!value.id && value.id) || null,
      messageModel: value.messageModel,
      customTemplate: value.customTemplate,
      type: value.type,
      messageId: (!!value.messageId && value.messageId) || null,
      content: {
        object: value.object || '',
        content: value.content || '',
      },
      language: value.language || null,
    },
    modifiedCurrentAction: {
      content: { object: value.object || '', content: value.content || '' },
      language: value.language || null,
    },
  },
});

const onSmsContentChange = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    modifiedCurrentAction: {
      ...state.actions.modifiedCurrentAction,
      content: value,
    },
  },
});

const onMailEditorLoaded = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    modifiedCurrentAction: {
      ...state.actions.modifiedCurrentAction,
      mailEditor: value,
    },
  },
});

const onMailContentChange = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    modifiedCurrentAction: {
      ...state.actions.modifiedCurrentAction,
      content: {
        ...state.actions.modifiedCurrentAction.content,
        content: value,
      },
    },
  },
});

const onContentLangChange = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    modifiedCurrentAction: {
      ...state.actions.modifiedCurrentAction,
      language: value,
    },
  },
});

const onMailObjectChange = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    modifiedCurrentAction: {
      ...state.actions.modifiedCurrentAction,
      content: {
        ...state.actions.modifiedCurrentAction.content,
        object: value,
      },
    },
  },
});

const onNotifyEditorLoaded = onMailEditorLoaded;

const onNotifyObjectChange = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    modifiedCurrentAction: {
      ...state.actions.modifiedCurrentAction,
      content: {
        ...state.actions.modifiedCurrentAction.content,
        object: value,
      },
    },
  },
});

const onNotifyContentChange = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    modifiedCurrentAction: {
      ...state.actions.modifiedCurrentAction,
      content: {
        ...state.actions.modifiedCurrentAction.content,
        content: value,
      },
    },
  },
});

const saveSMSActionContent = async (
  modifiedContent,
  campaignId,
  messageId,
  messageModel,
  customTemplate,
  actionId,
  callApi
) => {
  let campaignAction;

  if ((messageModel && messageModel.generic) || (customTemplate && customTemplate.generic)) {
    const { content } = modifiedContent;
    const storedFileId = await callApi(apiClient.upload, [
      contentEditorUtils.htmlToFreemarkerSms(content),
    ]);
    const message = await callApi(apiClient.insertOrUpdateMessage, [
      {
        id: messageId || null,
        contentStoredFileId: storedFileId || null,
      },
    ]);

    campaignAction = await callApi(apiClient.insertOrUpdateCampaignAction, [
      {
        id: actionId || null,
        actionTypeId: ActionType.SMS.id,
        campaignId,
        messageId: message.id,
        language: modifiedContent.language,
      },
    ]);
    return campaignAction;
  }
  // from this point only non generic messageModel treatment
  campaignAction = await callApi(apiClient.insertOrUpdateCampaignAction, [
    {
      id: actionId || null,
      actionTypeId: ActionType.SMS.id,
      campaignId,
      messageId: null,
      messageModelId: (messageModel && messageModel.id) || null,
      templateId: (customTemplate && customTemplate.id) || null,
      language: modifiedContent.language,
    },
  ]);
  return campaignAction;
};

const saveEmailActionContent = async (
  modifiedContent,
  campaignId,
  messageId,
  messageModel,
  customTemplate,
  actionId,
  actionTypeId,
  callApi
) => {
  let campaignAction;

  if ((messageModel && messageModel.generic) || (customTemplate && customTemplate.generic)) {
    const { object } = modifiedContent.content;
    const { mailEditor } = modifiedContent;

    const storedFileId = await callApi(apiClient.upload, [
      contentEditorUtils.htmlToFreemarker(
        null,
        mailEditor,
        [
          ActionType.EMAIL.id,
          ActionType.EMAIL_COACH.id,
          ActionType.EMAIL_INVITATION.id,
          ActionType.EMAIL_SPONSORSHIP,
        ].indexOf(actionTypeId) !== -1
      ),
      'text/html',
    ]);

    const message = await callApi(apiClient.insertOrUpdateMessage, [
      {
        id: messageId || null,
        contentStoredFileId: storedFileId,
        object: contentEditorUtils.htmlToFreemarker(object),
      },
    ]);
    campaignAction = await callApi(apiClient.insertOrUpdateCampaignAction, [
      {
        id: actionId || null,
        actionTypeId,
        campaignId,
        messageId: message.id,
        language: modifiedContent.language, // should be unic changing data
      },
    ]);
    return campaignAction;
  }

  // from this point only non generic messageModel treatment

  campaignAction = await callApi(apiClient.insertOrUpdateCampaignAction, [
    {
      id: actionId || null,
      actionTypeId,
      campaignId,
      messageId: null,
      messageModelId: (messageModel && messageModel.id) || null,
      templateId: (customTemplate && customTemplate.id) || null,
      language: modifiedContent.language,
    },
  ]);
  return campaignAction;
};

const saveNotifyActionContent = saveEmailActionContent;

export const deleteCmpAction = (cmpActionId, showAlert) => (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };

  const callApi = getApiCaller(showAlert)(dispatch, getState);

  const doDelete = async id => {
    setLoader(true);
    await callApi(apiClient.deleteCampaignAction, [id]);

    const editedCmp = getEditedCampaign(getState());

    const allActions = getCurrentActionsData(getState()).campaignActions;

    const actionToDelete = allActions.filter(a => a.id === id)[0];

    const actionToDeleteHasDuplicateBeforeDelete =
      editedCmp.duplicates.indexOf(actionToDelete.actionTypeId) !== -1;
    let actionToDeleteHasDuplicateAfterDelete = actionToDeleteHasDuplicateBeforeDelete;

    if (actionToDeleteHasDuplicateBeforeDelete) {
      const actionCount = allActions.reduce(
        (count, action) =>
          action.id !== actionToDelete.id && action.actionTypeId === actionToDelete.actionTypeId
            ? count + 1
            : count,
        0
      );
      actionToDeleteHasDuplicateAfterDelete = actionCount > 1;
    }

    let { duplicates } = editedCmp;
    if (actionToDeleteHasDuplicateBeforeDelete && !actionToDeleteHasDuplicateAfterDelete) {
      duplicates = duplicates.filter(atId => atId !== actionToDelete.actionTypeId);
    }

    dispatch({ type: 'REMOVE_CMP_ACTION', value: id });
    let cmpActionTypeProp = '';
    const { campaignActions } = getCurrentActionsData(getState());
    if (campaignActions.length) {
      campaignActions.forEach(action => {
        let type = '';
        if (cmpActionTypeProp !== '') type = ',';
        if (ActionTypeById[action.actionTypeId]) {
          type += ActionType[ActionTypeById[action.actionTypeId]].name;
        } else {
          type += '*';
        }
        cmpActionTypeProp += type;
      });
    }

    await callApi(apiClient.insertOrUpdateCampaign, [
      {
        ...editedCmp,
        actionType: cmpActionTypeProp,
      },
    ]);

    dispatch({
      type: 'UPDATE_CAMPAIGN',
      value: {
        ...editedCmp,
        actionType: cmpActionTypeProp,
        duplicates,
      },
    });

    dispatch({
      type: 'UPDATE_EDITED_CAMPAIGN',
      value: {
        ...editedCmp,
        actionType: cmpActionTypeProp,
        duplicates,
      },
    });
    dispatch(
      enqueueSnackbar({
        message: utils.getLang('smartmessaging.notifications.deletedContent'),
        options: {
          variant: 'warning',
        },
      })
    );
  };

  showAlert({
    type: 'warning',
    title: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaignAction.title'),
    msg: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaignAction'),
    onConfirm() {
      showAlert(null);
      doDelete(cmpActionId).finally(() => setLoader(false));
    },
    onDismiss() {
      showAlert(null);
    },
  });
};

export const checkContentValidity = state => {
  const actionTypeId = getCurrentActionType(state);
  switch (actionTypeId) {
    case ActionType.EMAIL.id:
    case ActionType.EMAIL_COACH.id:
    case ActionType.MEMBER_NOTIFICATION.id:
    case ActionType.EMAIL_SPONSORSHIP.id:
    case ActionType.EMAIL_INVITATION.id: {
      const { content } = getModifiedContent(state);
      return contentEditorUtils.checkMailContentValidity(content);
    }
    case ActionType.SMS.id: {
      const { content } = getModifiedContent(state);
      return smsUtils.checkSMSContentValidity(content, 0);
    }
    default:
      return { isValid: true, invalidities: [] };
  }
};

export const saveActionContent = (callback, showAlert) => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  // IF actionTypeId = null
  // remote get actions for campaign and actiontype
  // if an action of the same type comes, Warn and Stop SaveProcess,
  // back to actionList witgh upTodate Actions

  const asyncFn = async () => {
    setLoader(true);
    const { actionTypeId, editedCmp, modifiedContent, currentAction } = {
      actionTypeId: getCurrentActionType(getState()),
      editedCmp: getEditedCampaign(getState()),
      modifiedContent: getModifiedContent(getState()),
      currentAction: getCurrentActionsData(getState()).currentAction,
    };
    // GET THE MODIFIED CURRENT MODIFIED CONTENT AND OBJECT
    const messageId = currentAction.messageId || null;
    let updatedCampaignAction;
    switch (actionTypeId) {
      case ActionType.EMAIL.id:
      case ActionType.EMAIL_COACH.id:
      case ActionType.EMAIL_SPONSORSHIP.id:
      case ActionType.EMAIL_INVITATION.id: {
        // check l'optin et afficher les popin
        updatedCampaignAction = await saveEmailActionContent(
          modifiedContent,
          editedCmp.id,
          messageId,
          currentAction.messageModel,
          currentAction.customTemplate,
          currentAction.id || null,
          actionTypeId,
          callApi
        );
        break;
      }
      case ActionType.MEMBER_NOTIFICATION.id:
        updatedCampaignAction = await saveNotifyActionContent(
          modifiedContent,
          editedCmp.id,
          messageId,
          currentAction.messageModel,
          currentAction.customTemplate,
          currentAction.id || null,
          actionTypeId,
          callApi
        );
        break;
      case ActionType.SMS.id: {
        updatedCampaignAction = await saveSMSActionContent(
          modifiedContent,
          editedCmp.id,
          messageId,
          currentAction.messageModel,
          currentAction.customTemplate,
          currentAction.id || null,
          callApi
        );
        break;
      }
      default:
        break;
    }
    // if it is action crea
    if (!currentAction.id) {
      dispatch({ type: 'ADD_CMP_ACTION', value: updatedCampaignAction });

      let cmpActionTypeProp = '';
      const { campaignActions } = getCurrentActionsData(getState());
      if (campaignActions.length) {
        campaignActions.forEach(action => {
          if (cmpActionTypeProp !== '') cmpActionTypeProp += ',';
          cmpActionTypeProp += ActionType[ActionTypeById[action.actionTypeId]].name;
        });
      }

      await callApi(apiClient.insertOrUpdateCampaign, [
        {
          ...editedCmp,
          actionType: cmpActionTypeProp,
        },
      ]);

      dispatch({ type: 'UPDATE_CAMPAIGN', value: { ...editedCmp, actionType: cmpActionTypeProp } });
      dispatch({
        type: 'UPDATE_EDITED_CAMPAIGN',
        value: { ...editedCmp, actionType: cmpActionTypeProp },
      });
    } else {
      dispatch({
        type: 'UPDATE_CAMPAIGN_ACTION',
        value: updatedCampaignAction,
      });
    }

    dispatch(
      enqueueSnackbar({
        message: `${utils.getLang('smartmessaging.notifications.savedContent')}`,
        options: {
          variant: 'success',
        },
      })
    );

    if (callback) {
      callback();
    }
  };
  const doProceed = async () => {
    await asyncFn().finally(() => {
      setLoader(false);
    });
  };

  const { actionTypeId, editedCmp, contentValidity } = {
    actionTypeId: getCurrentActionType(getState()),
    editedCmp: getEditedCampaign(getState()),
    contentValidity: checkContentValidity(getState()),
  };

  const alertCfg = { alert: false, messages: [] };
  if (['legal', 'news'].indexOf(editedCmp.optinType) !== -1) {
    alertCfg.alert = true;
    alertCfg.messages.push(
      utils.stringFormat(utils.getLang('smartmessaging.contentEditor.marketingWarningContent'), [
        utils.getLang(`smartmessaging.diffusionType.${editedCmp.optinType}`),
      ])
    );
  }

  if (ActionType.EMAIL.id === actionTypeId && !contentValidity.hasOptoutLink) {
    alertCfg.alert = true;
    alertCfg.messages.push(utils.getLang('smartmessaging.contentEditor.mail.optoutLinkMissing'));
  }

  if (alertCfg.alert) {
    showAlert({
      title: utils.getLang('smartmessaging.contentWarning.title'),
      msg: `<ul>${alertCfg.messages.map(msg => `<li>${msg}</li>`).join()}</ul>`,
      onConfirm: () => {
        doProceed();
        showAlert(null);
      },
      onDismiss() {
        showAlert(null);
      },
    });
  } else doProceed();
};

export const selectMailMessage = (campaignAction, fieldsModels, actionTypeId, showAlert) => async (
  dispatch,
  getState
) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  let messageModel;
  let customTemplate;
  let fileId;
  let actionMessage;
  let mailObject;
  let mailContent;

  const smParams = getSmParams(getState());

  const clubParams = {
    headerFileId: smParams.headerStoredfileId || null,
    footerFileId: smParams.footerStoredfileId || null,
  };
  if (campaignAction.messageId) {
    actionMessage = await callApi(apiClient.getMessage, [campaignAction.messageId]);
    fileId = actionMessage.contentStoredFileId;
    messageModel = { generic: true };
  } else if (campaignAction.templateId) {
    customTemplate = await callApi(templateApi.getTemplate, [campaignAction.templateId]);
    fileId = customTemplate.storedfileId;
  } else {
    messageModel = await callApi(apiClient.getMessageModel, [campaignAction.messageModelId]);
    fileId = messageModel.storedFileId;
  }
  mailContent = await callApi(apiClient.get, [utils.link(fileId)]);

  mailContent = contentEditorUtils.freemarkerToHtml(mailContent, fieldsModels, clubParams);

  if (messageModel) {
    if (messageModel.generic) mailObject = actionMessage.object || '';
    else mailObject = messageModel.object || '';
  } else {
    mailObject = customTemplate.subject || '';
  }

  mailObject = contentEditorUtils.freemarkerToHtml(mailObject, fieldsModels, clubParams);

  dispatch({
    type: 'RECEIVE_MAIL_CONTENT',
    value: {
      messageModel,
      customTemplate,
      id: campaignAction.id || null,
      messageId: (actionMessage && actionMessage.id) || null,
      object: mailObject,
      content: mailContent,
      type: actionTypeId,
      language: campaignAction.language,
    },
  });
};

export const selectNotifyMessage = (
  campaignAction,
  fieldsModels,
  actionTypeId,
  showAlert
) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  let messageModel;
  let customTemplate;
  let fileId;
  let actionMessage;
  let mailObject;
  let mailContent;

  const smParams = getSmParams(getState());

  const clubParams = {
    headerFileId: smParams.headerStoredfileId || null,
    footerFileId: smParams.footerStoredfileId || null,
  };
  if (campaignAction.messageId) {
    actionMessage = await callApi(apiClient.getMessage, [campaignAction.messageId]);
    fileId = actionMessage.contentStoredFileId;
    messageModel = { generic: true };
  } else if (campaignAction.templateId) {
    customTemplate = await callApi(templateApi.getTemplate, [campaignAction.templateId]);
    fileId = customTemplate.storedfileId;
  } else {
    messageModel = await callApi(apiClient.getMessageModel, [campaignAction.messageModelId]);
    fileId = messageModel.storedFileId;
  }
  mailContent = await callApi(apiClient.get, [utils.link(fileId)]);

  mailContent = contentEditorUtils.freemarkerToHtml(mailContent, fieldsModels, clubParams);

  if (messageModel) {
    if (messageModel.generic) mailObject = actionMessage.object || '';
    else mailObject = messageModel.object || '';
  } else {
    mailObject = customTemplate.subject || '';
  }

  mailObject = contentEditorUtils.freemarkerToHtml(mailObject, fieldsModels, clubParams);

  dispatch({
    type: 'RECEIVE_NOTIFICATION_CONTENT',
    value: {
      messageModel,
      customTemplate,
      id: campaignAction.id || null,
      messageId: (actionMessage && actionMessage.id) || null,
      object: mailObject,
      content: mailContent,
      type: actionTypeId,
      language: campaignAction.language,
    },
  });
};

export const selectSMSMessage = (campaignAction, fieldsModels, showAlert) => async (
  dispatch,
  getState
) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  let messageModel;
  let fileId;
  let actionMessage;
  let smsContent;
  let customTemplate;

  if (campaignAction.messageId) {
    actionMessage = await callApi(apiClient.getMessage, [campaignAction.messageId]);
    fileId = actionMessage.contentStoredFileId;
    messageModel = { generic: true };
  } else if (campaignAction.templateId) {
    customTemplate = await callApi(templateApi.getTemplate, [campaignAction.templateId]);
    fileId = customTemplate.storedfileId;
  } else {
    messageModel = await callApi(apiClient.getMessageModel, [campaignAction.messageModelId]);
    fileId = messageModel.storedFileId;
  }

  smsContent = await callApi(apiClient.get, [utils.link(fileId)]);
  smsContent = contentEditorUtils.smsFreemarkerToHtml(smsContent, fieldsModels);

  dispatch({
    type: 'RECEIVE_SMS_CONTENT',
    value: {
      messageModel,
      customTemplate,
      id: campaignAction.id || null,
      messageId: (actionMessage && actionMessage.id) || null,
      content: smsContent,
      language: campaignAction.language,
    },
  });
};

export const selectMailMessageModel = (
  lang,
  messageModel,
  fieldsModels,
  actionTypeId,
  showAlert
) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const freemarker = await callApi(apiClient.get, [utils.link(messageModel.storedFileId)]);
  const smParams = getSmParams(getState());
  const clubParams = {
    headerFileId: smParams.headerStoredfileId,
    footerFileId: smParams.footerStoredfileId,
  };
  const content = contentEditorUtils.freemarkerToHtml(freemarker, fieldsModels, clubParams);
  const object = contentEditorUtils.freemarkerToHtml(
    messageModel.object || '',
    fieldsModels,
    clubParams
  );
  dispatch({
    type: 'RECEIVE_MAIL_CONTENT',
    value: {
      id: null,
      messageModel,
      messageId: null,
      object: object || '',
      content,
      type: actionTypeId,
      language: lang,
    },
  });
};

export const selectSmsMessageModel = (lang, messageModel, fieldsModels, showAlert) => async (
  dispatch,
  getState
) => {
  const freemarker = await getApiCaller(showAlert)(dispatch, getState)(apiClient.get, [
    utils.link(messageModel.storedFileId),
  ]);

  const content = contentEditorUtils.smsFreemarkerToHtml(freemarker, fieldsModels);

  dispatch({
    type: 'RECEIVE_SMS_CONTENT',
    value: {
      id: null,
      messageModel,
      messageId: null,
      content,
      language: lang,
    },
  });
};

const selectEmailCustomTemplate = (
  lang,
  customTemplate,
  fieldsModels,
  actionTypeId,
  showAlert
) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const freemarker = await callApi(apiClient.get, [utils.link(customTemplate.storedfileId)]);
  const smParams = getSmParams(getState());
  const clubParams = {
    headerFileId: smParams.headerStoredfileId,
    footerFileId: smParams.footerStoredfileId,
  };
  const content = contentEditorUtils.freemarkerToHtml(freemarker, fieldsModels, clubParams);
  const object = contentEditorUtils.freemarkerToHtml(
    customTemplate.subject || '',
    fieldsModels,
    clubParams
  );
  dispatch({
    type: 'RECEIVE_MAIL_CONTENT',
    value: {
      id: null,
      customTemplate,
      messageId: null,
      object: object || '',
      content,
      type: actionTypeId,
      language: lang,
    },
  });
};

const selectSmsCustomTemplate = (lang, customTemplate, fieldsModels, showAlert) => async (
  dispatch,
  getState
) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const freemarker = await callApi(apiClient.get, [utils.link(customTemplate.storedfileId)]);
  const smParams = getSmParams(getState());
  const clubParams = {
    headerFileId: smParams.headerStoredfileId,
    footerFileId: smParams.footerStoredfileId,
  };
  const content = contentEditorUtils.smsFreemarkerToHtml(freemarker, fieldsModels, clubParams);
  dispatch({
    type: 'RECEIVE_SMS_CONTENT',
    value: {
      id: null,
      customTemplate,
      messageId: null,
      content,
      language: lang,
    },
  });
};

export const selectCustomTemplate = (
  lang,
  customTemplate,
  fieldsModels,
  showAlert
) => async dispatch => {
  let selectTemplate = async () => null;
  switch (customTemplate.actionTypeId) {
    case ActionType.EMAIL.id:
      selectTemplate = selectEmailCustomTemplate(
        lang,
        customTemplate,
        fieldsModels,
        customTemplate.actionTypeId,
        showAlert
      );
      break;
    case ActionType.SMS.id:
      selectTemplate = selectSmsCustomTemplate(lang, customTemplate, fieldsModels, showAlert);
      break;
    default:
      break;
  }
  await dispatch(selectTemplate);
};

export const selectUploadedMailModel = (lang, data, fieldsModels, actionTypeId) => (
  dispatch,
  getState
) => {
  const smParams = getSmParams(getState());
  const clubParams = {
    headerFileId: smParams.headerStoredfileId,
    footerFileId: smParams.footerStoredfileId,
  };

  const content = contentEditorUtils.freemarkerToHtml(data, fieldsModels, clubParams);

  dispatch({
    type: 'RECEIVE_MAIL_CONTENT',
    value: {
      id: null,
      messageModel: { generic: true },
      messageId: null,
      object: '',
      content,
      type: actionTypeId,
      language: lang,
    },
  });
};
export const selectNotificationUploadedModel = (lang, data, fieldsModels, actionTypeId) => async (
  dispatch,
  getState
) => {
  const smParams = getSmParams(getState());
  const clubParams = {
    headerFileId: smParams.headerStoredfileId,
    footerFileId: smParams.footerStoredfileId,
  };

  const content = contentEditorUtils.freemarkerToHtml(data, fieldsModels, clubParams);

  dispatch({
    type: 'RECEIVE_NOTIFICATION_CONTENT',
    value: {
      id: null,
      messageModel: { generic: true },
      messageId: null,
      object: '',
      content,
      type: actionTypeId,
      language: lang,
    },
  });
};

export const selectUploadedSmsModel = (lang, data, fieldsModels) => async dispatch => {
  const content = contentEditorUtils.smsFreemarkerToHtml(data, fieldsModels);

  dispatch({
    type: 'RECEIVE_SMS_CONTENT',
    value: {
      id: null,
      messageModel: { generic: true },
      messageId: null,
      content,
      language: lang,
    },
  });
};

export const selectNotificationMessageModel = (
  lang,
  messageModel,
  fieldsModels,
  actionTypeId,
  showAlert
) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const freemarker = await callApi(apiClient.get, [utils.link(messageModel.storedFileId)]);
  const smParams = getSmParams(getState());
  const clubParams = {
    headerFileId: smParams.headerStoredfileId,
    footerFileId: smParams.footerStoredfileId,
  };
  const content = contentEditorUtils.freemarkerToHtml(freemarker, fieldsModels);
  const object = contentEditorUtils.freemarkerToHtml(
    messageModel.object || '',
    fieldsModels,
    clubParams
  );
  dispatch({
    type: 'RECEIVE_NOTIFICATION_CONTENT',
    value: {
      id: null,
      messageModel,
      messageId: null,
      object: object || '',
      content,
      type: actionTypeId,
      language: lang,
    },
  });
};

const receiveAvailableActions = (state, { value }) => ({
  ...state,
  actions: { ...state.actions, availableActionTypes: value },
});

const receiveCmpActions = (state, { value }) => ({
  ...state,
  actions: { ...state.actions, campaignActions: value },
});

const addCmpAction = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    campaignActions: [...state.actions.campaignActions].concat({ ...value }),
  },
});

const removeCmpAction = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    campaignActions: [...state.actions.campaignActions].filter(cmpAction => cmpAction.id !== value),
  },
});

const updateCampaignAction = (state, { value }) => ({
  ...state,
  actions: {
    ...state.actions,
    campaignActions: [...state.actions.campaignActions].map(ca =>
      ca.id === value.id ? { ...ca, ...value } : ca
    ),
    currentAction: state.actions.currentAction
      ? { ...state.actions.currentAction, ...value }
      : state.actions.currentAction,
  },
});

export const loadAvailableActionsByRmType = (requestModelTypeId, showAlert) => async (
  dispatch,
  getState
) => {
  // load actions
  const requestModelByRequestModelTypeIdMap = mapRequestModelByRMTypeId(getState());
  const availableActionTypeList = await getApiCaller(showAlert)(dispatch, getState)(
    apiClient.listAvailableActionsForRequestModel,
    [requestModelByRequestModelTypeIdMap[requestModelTypeId].id] // this is a requestModelId
  );
  dispatch({ type: 'RECEIVE_AVAILABLE_ACTIONS', value: availableActionTypeList });
};

export const getSmsCount = state => smsUtils.getCount(getModifiedContent(state).content);

export const mapActionsByTypeId = state =>
  state.campaignEditor.actions.availableActionTypes.reduce((map = {}, actionType) => {
    const newMap = { ...map };

    const campaignActions = state.campaignEditor.actions.campaignActions.filter(
      action => action.actionTypeId === actionType.id
    );

    newMap[actionType.id] = campaignActions.length ? campaignActions : null;
    return newMap;
  }, {});

export const getDistinctActiveFieldKeys = state => {
  const reducer = (distinctKeyList = [], activeFieldConfig) => {
    if (distinctKeyList.indexOf(activeFieldConfig.model.key) === -1) {
      const copy = distinctKeyList.slice();
      copy.push(activeFieldConfig.model.key);
      return copy;
    }
    return distinctKeyList;
  };
  return state.campaignEditor.recipeFieldsConfig.activeFields.reduce(reducer, []);
};

// ------------------------------------------------------------------
/* #endregion CAMPAIGNACTIONS */
// ------------------------------------------------------------------

// ------------------------------------------------------------------
/* #region STEP1 */
// ------------------------------------------------------------------
const selectCampaignType = (state, { value }) => ({
  ...state,
  editedCampaign: {
    ...state.editedCampaign,
    eventDriven: value.eventDriven,
    requestModelTypeId: value.requestModelTypeId,
    optinType: value.optinType,
  },
  selectedType: value,
});

const selectOptinType = (state, { value }) => ({
  ...state,
  editedCampaign: {
    ...state.editedCampaign,
    optinType: value,
  },
});

export const checkCmpInfosValidity = state => {
  const editedCmp = getEditedCampaign(state);

  let message = '';
  let isValid = true;
  if (editedCmp) {
    if (!editedCmp.name.length) {
      isValid = false;
      message = "La campagne n'est pas nommée";
    }
  }
  return { isValid, message };
};
// ------------------------------------------------------------------
/* #endregion STEP1 */
// ------------------------------------------------------------------

// ------------------------------------------------------------------
/* #region SCHEDULER(STEP4) */
// ------------------------------------------------------------------

const receiveCampaignPeriods = (state, { value }) => ({
  ...state,
  schedule: { editedCmpPeriods: value },
});

const addNewPeriod = state => ({
  ...state,
  schedule: {
    ...state.schedule,
    editedCmpPeriods: state.schedule.editedCmpPeriods.concat({
      id: null,
      campaignId: state.editedCampaign.id,
      startDate: '',
      endDate: '',
      time: '',
      replay: false,
    }),
  },
});

const removePeriod = (state, { value }) => ({
  ...state,
  schedule: {
    ...state.schedule,
    editedCmpPeriods: [...state.schedule.editedCmpPeriods].map(period => {
      if (state.schedule.editedCmpPeriods.indexOf(period) === value) {
        return { ...period, removed: true };
      }
      return { ...period };
    }),
  },
});

// for materialuipickers
const onPeriodChange = (state, { value }) => ({
  ...state,
  schedule: {
    ...state.schedule,
    editedCmpPeriods: [...state.schedule.editedCmpPeriods].map(period => {
      if (state.schedule.editedCmpPeriods.indexOf(period) === value.index) {
        return {
          ...period,
          [value.key]: value.newValue,
          modified: true,
        };
      }
      return period;
    }),
  },
});

const checkPeriodValidity = (period, eventDriven) => {
  let isValid = true;
  let message = `period is valid`;
  const startDate = new Date(period.startDate);
  const isEmptyEndDate = period.endDate === null || period.endDate === '';
  let endDate;
  if (!isEmptyEndDate) {
    endDate = new Date(period.endDate);
  }
  const isValidStartDate = utils.isValidDate(startDate);
  const isValidEndDate = isEmptyEndDate || utils.isValidDate(endDate);

  isValid = isValidStartDate && isValidEndDate;
  if (!isValidStartDate) {
    message = 'Start date is Invalid';
  }
  if (!isValidEndDate) {
    if (isValidStartDate) message = 'End date is Invalid';
    else message += 'End date is Invalid';
  }

  if (isValid && !isEmptyEndDate) {
    // isValidStartDate && isValidEndDate
    isValid = startDate.getTime() <= endDate.getTime();
    if (!isValid) {
      message = 'Start date must be lower than end date';
    }
  }
  if (isValid && !eventDriven) {
    // test the validity of time if !eventDriven
    if (!period.time || !period.time.length) {
      isValid = false;
      message = 'Time is missing';
    }
  }

  return { isValid, message };
};

export const checkScheduleValidity = state => {
  const editedCampaign = getEditedCampaign(state);
  const requestModelByRequestModelTypeIdMap = mapRequestModelByRMTypeId(state);
  const requestModel = requestModelByRequestModelTypeIdMap[editedCampaign.requestModelTypeId];
  const periods = getUnremovedPeriods(state);
  const invalidPeriods = [];
  let scheduleIsValid = true;
  let index = 0;

  periods.forEach(period => {
    const periodValidity = checkPeriodValidity(
      period,
      editedCampaign.eventDriven || ['hour'].indexOf(requestModel.periodicity) !== -1
    );
    if (!periodValidity.isValid) {
      invalidPeriods.push({
        index,
        ...periodValidity,
        message: `Period ${index + 1} : ${periodValidity.message}`,
      });
    }
    if (scheduleIsValid && !periodValidity.isValid) scheduleIsValid = false;
    index += 1;
  });
  return { invalidPeriods, isValid: scheduleIsValid };
};

// ------------------------------------------------------------------
/* #endregion SCHEDULER(STEP4) */
// ------------------------------------------------------------------

// ------------------------------------------------------------------
/* #region EDITEDCAMPAIGN */
// ------------------------------------------------------------------

export const editCampaign = (campaignToEdit, showAlert) => async (dispatch, getState) => {
  // if the campaign is not a new campaign : load cmpaign datas (recipe, actions, schedule)
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const callApi = getApiCaller(showAlert)(dispatch, getState);

  const asyncFn = async () => {
    setLoader(true);
    let isWritable = true;
    let optinType = campaignToEdit.optinType || null;
    if (campaignToEdit.id) {
      isWritable = await callApi(apiClient.campaignIsWritable, [campaignToEdit.id]);
      if (campaignToEdit.requestModelTypeId) {
        const requestModelByRequestModelTypeIdMap = mapRequestModelByRMTypeId(getState());
        const requestModel =
          (campaignToEdit.requestModelTypeId &&
            requestModelByRequestModelTypeIdMap[campaignToEdit.requestModelTypeId]) ||
          null;
        optinType = campaignToEdit.optinType || requestModel.optinType;
        // load recipe config
        const requestModelParams = await callApi(apiClient.loadRecipeParameters, [requestModel.id]);

        dispatch({
          type: 'RECEIVE_RM_PARAMS_MODEL',
          value: requestModelParams,
        });

        let setupId = false;

        if (campaignToEdit.recipeId) {
          const sId = getState().recipeList.recipeListById[campaignToEdit.recipeId].setupId;
          setupId = sId || setupId;
        }
        if (setupId) {
          const setup = await callApi(apiClient.getSetup, [setupId]);
          const migrateClubsValue = oldValue => {
            const newDescriptor = requestModelParams.filter(rmp => rmp.key === 'clubs');
            const newValue = {
              key: newDescriptor.key,
              values: [
                {
                  ...oldValue,
                  key: 'clubs_single',
                },
              ],
              java: newDescriptor.javaParameter,
              operatorId: oldValue.operatorId,
              typeId: ParameterType[newDescriptor.type],
            };
            return newValue;
          };

          const processedSetup = {
            setupId: setup.id,
            ...processRecipeConfig(
              {
                ...setup,
                value: setup.value.map(v =>
                  v.key === 'clubs' && v.typeId === ParameterType.FOREIGN_KEY
                    ? migrateClubsValue(v)
                    : v
                ),
              },
              requestModelParams
            ),
          };

          dispatch({
            type: 'RECEIVE_RECIPE_CONFIG',
            value: processedSetup,
          });
        }

        // load campaign actions
        const availableActionTypeList = await callApi(
          apiClient.listAvailableActionsForRequestModel,
          [requestModel.id]
        );
        dispatch({ type: 'RECEIVE_AVAILABLE_ACTIONS', value: availableActionTypeList });

        const cmpActionList = await callApi(apiClient.loadCampaignActions, [campaignToEdit.id]);
        dispatch({ type: 'RECEIVE_CMP_ACTIONS', value: cmpActionList });

        // load campaign schedule
        const periodList = await callApi(apiClient.getPeriodsByCampaignId, [campaignToEdit.id]);
        dispatch({ type: 'RECEIVE_CMP_PERIODS', value: periodList });
      }
    }

    dispatch({
      type: 'RECEIVE_CAMPAIGN_TO_EDIT',
      value: {
        ...campaignToEdit,
        optinType,
        isWritable,
      },
    });
  };

  await asyncFn().finally(() => setLoader(false));
};

const receiveCampaignToEdit = (state, action) => ({
  ...state,
  editedCampaign: { ...action.value },
});

const createCampaign = (state, action) => ({
  ...state,
  editedCampaign: {
    id: null,
    name: '',
    recipeId: null,
    eventDriven: null,
    actionType: null,
    creationDate: new Date().getTime(),
    enabled: false,
    isWritable: true,
    byPass: false,
    replay: false,
    smartjourneyId: action.value ? action.value.groupId || null : null,
    duplicates: [],
  },
});

const updateEditedCampaign = (state, action) => ({
  ...state,
  editedCampaign: { ...state.editedCampaign, ...action.value },
});

const clearEditorData = () => ({
  ...initialState,
});

export const checkCampaignValidity = state => {
  const editedCampaign = getEditedCampaign(state);
  const invalidities = [];
  let isValid;
  if (editedCampaign) {
    const infosValidity = checkCmpInfosValidity(state);
    const recipeValidity = checkRecipeCfgValidity(state);
    const scheduleValidity = checkScheduleValidity(state);
    isValid = recipeValidity.isValid && scheduleValidity.isValid && infosValidity.isValid;

    if (!isValid) {
      if (!recipeValidity.isValid) {
        invalidities.push(...recipeValidity.invalidFields);
      }
      if (!scheduleValidity.isValid) {
        invalidities.push('scheduleValidity');
      }
      if (!infosValidity.isValid) {
        invalidities.push('infosValidity');
      }
    }
  } else {
    isValid = false;
    invalidities.push('No editedCampaign');
  }
  return { isValid, invalidities };
};

export const saveCampaign = (bypassActivationTest = false, showAlert) => async (
  dispatch,
  getState
) => {
  // save schedule
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };

  const asyncFn = async () => {
    setLoader(true);
    const editedPeriods = getPeriods(getState());
    if (editedPeriods && editedPeriods.length) {
      let updatedPeriods = [];
      let upToDatePeriods = [];

      editedPeriods.forEach(period => {
        if (!period.removed && (!period.id || period.modified)) {
          upToDatePeriods.push('emptyslot');
          updatedPeriods.push(callApi(apiClient.insertOrUpdatePeriod, [period]));
        } else if (period.removed && period.id) {
          callApi(apiClient.deletePeriod, [period.id]);
        } else {
          upToDatePeriods.push(period);
        }
      });

      updatedPeriods = await Promise.all(updatedPeriods);

      let count = 0;
      upToDatePeriods = upToDatePeriods.map(upToDatePeriod => {
        if (upToDatePeriod === 'emptyslot') {
          count += 1;
          return updatedPeriods[count - 1];
        }
        return upToDatePeriod;
      });
      dispatch({ type: 'RECEIVE_CMP_PERIODS', value: upToDatePeriods });
    }

    // save recipe/setup (setup)
    const currentRecipeFieldConfig = getEditedCampaignFieldsConfig(getState());

    let savedRecipeId = false;

    if (currentRecipeFieldConfig.loaded || getCampaignEditorData(getState()).selectedType) {
      // has been edited once and we want to save it as it is now.
      // If has not been loaded, could mean that a recipe exist but has not been loaded. we don't want to overwrite it
      if (currentRecipeFieldConfig.loaded) {
        let setup;
        if (checkRecipeCfgValidity(getState()).isValid) {
          setup = await saveEditedRecipeSetup(currentRecipeFieldConfig, callApi);
          const processedSetup = {
            setupId: setup.id,
            ...processRecipeConfig(setup, getEditedCampaignRequestModelParams(getState())),
          };
          dispatch({
            type: 'RECEIVE_RECIPE_CONFIG',
            value: processedSetup,
          });
        }
      }
      const recipe = await saveEditedRecipe(getCampaignEditorData(getState()), callApi);
      dispatch({ type: 'ADD_RECIPE', value: recipe });
      savedRecipeId = recipe.id;
    }

    // save campaign
    const campaignToSave = { ...getEditedCampaign(getState()) };
    if (savedRecipeId) {
      campaignToSave.recipeId = savedRecipeId;
    }

    let updatedCampaign = null;
    updatedCampaign = await callApi(apiClient.insertOrUpdateCampaign, [campaignToSave]);
    updatedCampaign = {
      ...campaignToSave,
      ...updatedCampaign,
      duplicates: JSON.parse(updatedCampaign.duplicates),
    };
    // if active, reactive with new values and disable if activation fails
    if (!bypassActivationTest && updatedCampaign.enabled) {
      const activationResponse = await callApi(apiClient.activateCampaign, [
        updatedCampaign.id,
        true,
      ]);

      if (!activationResponse.isSuccess) {
        const disableResponse = await callApi(apiClient.activateCampaign, [
          updatedCampaign.id,
          false,
        ]);
        if (disableResponse.isSuccess) {
          updatedCampaign.enabled = false;
        }
      }
    }

    const { selectedType } = getCampaignEditorData(getState());
    updatedCampaign.requestModelTypeId =
      campaignToSave.requestModelTypeId ||
      (selectedType && selectedType.requestModelTypeId) ||
      null;

    dispatch({ type: 'UPDATE_CAMPAIGN', value: updatedCampaign });
    dispatch({ type: 'UPDATE_EDITED_CAMPAIGN', value: updatedCampaign });

    dispatch(
      enqueueSnackbar({
        message: `${updatedCampaign.name}: ${utils.getLang(
          'smartmessaging.notifications.savedCampaign'
        )}`,
        options: {
          variant: 'success',
        },
      })
    );
    // load//reload actions
    if (campaignToSave.requestModelTypeId || getCampaignEditorData(getState()).selectedType) {
      const availableActionTypeList = await callApi(apiClient.listAvailableActionsForRequestModel, [
        getCurrentRequestModelId(getState()),
      ]);
      dispatch({ type: 'RECEIVE_AVAILABLE_ACTIONS', value: availableActionTypeList });

      const cmpActionList = await callApi(apiClient.loadCampaignActions, [updatedCampaign.id]);
      dispatch({ type: 'RECEIVE_CMP_ACTIONS', value: cmpActionList });
    }
  };

  await asyncFn().finally(() => {
    setLoader(false);
  });
};

export const activateEditedCampaign = showAlert => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };

  const callApi = getApiCaller(showAlert)(dispatch, getState);

  const asyncFn = async () => {
    setLoader(true);
    await dispatch(saveCampaign(true, showAlert));

    const editedCmp = getEditedCampaign(getState()); // cmp has been saved
    let upToDateCampaign;
    const activationResponse = await callApi(apiClient.activateCampaign, [
      editedCmp.id,
      !editedCmp.enabled,
    ]);
    if (activationResponse.isSuccess) {
      upToDateCampaign = await callApi(apiClient.getCampaign, [editedCmp.id]);
      upToDateCampaign.requestModelTypeId =
        editedCmp.requestModelTypeId || getCampaignEditorData(getState()).selectedType; // messy -- sketchy -- dirty
      const withObjDuplicates = {
        ...upToDateCampaign,
        duplicates: JSON.parse(upToDateCampaign.duplicates),
      };
      dispatch({ type: 'UPDATE_CAMPAIGN', value: withObjDuplicates });
      dispatch({ type: 'UPDATE_EDITED_CAMPAIGN', value: withObjDuplicates });
    } else {
      showAlert({
        type: 'warning',
        title: utils.getLang('smartmessaging.errorMessages.campaign.notActivable.title'),
        msg: utils.getLang(
          (activationResponse.error &&
            `smartmessaging.errorMessages.campaign.notActivable.${activationResponse.error}`) ||
            'smartmessaging.errorMessages.campaign.notActivable.message'
        ),
      });
    }
  };

  await asyncFn().finally(() => {
    setLoader(false);
  });
};

const goToStep = (state, action) => ({
  ...state,
  currentStep: action.value,
});

const logState = () => {};
// ------------------------------------------------------------------
/* #endregion EDITEDCAMPAIGN */
// ------------------------------------------------------------------

export default createReducer(initialState, {
  ADD_NEW_PERIOD: addNewPeriod,
  ADD_CMP_ACTION: addCmpAction,
  UPDATE_CAMPAIGN_ACTION: updateCampaignAction,
  ADD_ACTIVE_FIELD: addActiveField,
  ADD_GROUP_FIELD_LINE: addGroupSubFieldLine,
  CREATE_CAMPAIGN: createCampaign,
  CLEAR_CURRENT_ACTION: clearCurrentAction,
  CLEAR_EDITOR_DATA: clearEditorData,
  GO_TO_STEP: goToStep,
  LOGSTATE: logState,
  ON_RECIPE_FIELD_CHANGE: onRecipeFieldChange,
  ON_RECIPE_FIELD_OP_CHANGE: onRecipeFieldOpchange,
  ON_MAIL_OBJECT_CHANGE: onMailObjectChange,
  ON_CONTENT_LANG_CHANGE: onContentLangChange,
  ON_MAIL_CONTENT_CHANGE: onMailContentChange,
  ON_MAIL_EDITOR_LOADED: onMailEditorLoaded,
  ON_NOTIFY_OBJECT_CHANGE: onNotifyObjectChange,
  ON_NOTIFY_CONTENT_CHANGE: onNotifyContentChange,
  ON_NOTIFY_EDITOR_LOADED: onNotifyEditorLoaded,
  ON_SMS_CONTENT_CHANGE: onSmsContentChange,
  ON_PERIOD_CHANGE: onPeriodChange,
  REMOVE_RECIPE_GROUP_FIELD_LINE: removeRecipeGroupFieldLine,
  RECEIVE_CMP_ACTIONS: receiveCmpActions,
  REMOVE_ACTIVE_FIELD: removeActiveField,
  RECEIVE_RM_PARAMS_MODEL: receiveRequestModelParams,
  REMOVE_RECIPE_FIELD_LINE: removeRecipeFieldLine,
  REMOVE_CMP_ACTION: removeCmpAction,
  RECEIVE_CAMPAIGN_TO_EDIT: receiveCampaignToEdit,
  RECEIVE_AVAILABLE_ACTIONS: receiveAvailableActions,
  RECEIVE_MAIL_CONTENT: receiveMailContent,
  RECEIVE_SMS_CONTENT: receiveSmsContent,
  RECEIVE_NOTIFICATION_CONTENT: receiveNotificationContent,
  RECEIVE_CMP_PERIODS: receiveCampaignPeriods,
  RECEIVE_RECIPE_CONFIG: receiveRecipeConfig,
  REMOVE_PERIOD: removePeriod,
  SELECT_CAMPAIGN_TYPE: selectCampaignType,
  UPDATE_EDITED_CAMPAIGN: updateEditedCampaign,
  SELECT_OPTIN_TYPE: selectOptinType,
});
